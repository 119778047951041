import { useEffect, useState } from 'react';

import {
  FolderCode,
  FolderPath,
  JobFile,
  useDeleteFileMutation,
  useGetFilesQuery,
  useGetFoldersQuery,
} from 'src/features/JobFiles/JobFiles.service';
import { useAppSelector, useExpand, useRouteParams, useSelect } from 'src/utilities/hooks';
import { parseArrayToLogicalObject, parseRecordToLogicalObject } from 'src/utilities/parsers';
import { showSuccessMessage } from '../../utilities/notificationsService';

export function useJobFiles() {
  const { age, jobId, jobType } = useRouteParams();

  const [deleteItem, setDeleteItem] = useState<JobFile>();
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState(false);
  const [selectedFolder, setSelectedFolder] = useState({
    code: '',
    path: '',
  });
  const userId = useAppSelector((state) => state.user.details.id);

  const {
    data: folders = [],
    isFetching: areFoldersFetching,
    isSuccess: areFoldersLoadedSuccessfully,
  } = useGetFoldersQuery({ age, jobId, jobType, userId });

  const {
    data: files = { categorized: {}, raw: [] },
    isFetching: areFilesFetching,
    isSuccess: areFilesLoadedSuccessfully,
  } = useGetFilesQuery(
    { age, folderCode: selectedFolder.code, jobId, jobType, userId },
    { skip: !selectedFolder.code },
  );

  const { areAllItemsExpanded, expandedItems, toggleExpandAll, toggleExpandItem } = useExpand(
    parseRecordToLogicalObject(files.categorized),
    true,
  );

  const {
    areAllItemsSelected,
    selectedItemIds: selectedFileIds,
    toggleSelectAll,
    toggleSelectItem,
    toggleSelectMultiple,
  } = useSelect(parseArrayToLogicalObject(files.raw, 'id'));
  const [deleteFile] = useDeleteFileMutation();

  function handleCloseConfirmationDialog() {
    setIsConfirmationDialogOpen(false);
  }

  async function handleDeleteFile() {
    if (deleteItem) {
      deleteFile({
        fileId: deleteItem.id,
        userId,
      })
        .unwrap()
        .then(({ message }) => {
          setIsConfirmationDialogOpen(false);

          showSuccessMessage(message);
        });
    }
  }

  function handleOpenConfirmationDialog(file: JobFile) {
    setDeleteItem(file);
    setIsConfirmationDialogOpen(true);
  }

  function handleSelectFolder(code: FolderCode, path: FolderPath) {
    setSelectedFolder({
      code,
      path,
    });
  }

  useEffect(() => {
    if (areFoldersLoadedSuccessfully) {
      setSelectedFolder({
        code: folders[0].code,
        path: folders[0].path_name,
      });
    }
  }, [areFoldersLoadedSuccessfully]);

  useEffect(() => {
    toggleExpandAll(false);
    toggleSelectAll(false);
  }, [selectedFolder.code]);

  return {
    areAllItemsExpanded,
    areAllItemsSelected,
    areFilesFetching,
    areFilesLoadedSuccessfully,
    areFoldersFetching,
    categorizedFiles: files.categorized,
    deleteItem,
    expandedItems,
    folders,
    isConfirmationDialogOpen,
    onCloseConfirmationDialog: handleCloseConfirmationDialog,
    onDeleteFile: handleDeleteFile,
    onOpenConfirmationDialog: handleOpenConfirmationDialog,
    onSelectFolder: handleSelectFolder,
    rawFiles: files.raw,
    selectedFileIds,
    selectedFolder,
    toggleExpandAll,
    toggleExpandItem,
    toggleSelectAll,
    toggleSelectItem,
    toggleSelectMultiple,
  };
}
