import { useEffect, useState } from 'react';
import { useForm, useFormContext } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

import { LoadingButton } from '@mui/lab';
import { Box, Dialog, DialogActions, DialogContent, Paper } from '@mui/material';

import { Button } from 'src/components/Button';
import { WaveDialogTitle } from 'src/components/WaveDialogTitle';
import {
  useCreateCompareReplaceTemplateMutation,
  useDeleteCompareReplaceTemplateMutation,
  useGetCompareReplaceOptionsQuery,
  useGetCompareReplaceTemplatesQuery,
  useUpdateCompareReplaceTemplateMutation,
} from 'src/features/CompareReplaceDialog/CompareReplaceDialog.service';
import { CompareReplaceContent } from 'src/features/CompareReplaceDialog/components/CompareReplaceContent';
import { getDefaultValues } from 'src/features/CompareReplaceDialog/components/CompareReplaceContent/utils';
import { CompareReplaceDeleteTemplateDialog } from 'src/features/CompareReplaceDialog/components/CompareReplaceDeleteTemplateDialog';
import { CompareReplaceMenu } from 'src/features/CompareReplaceDialog/components/CompareReplaceMenu/CompareReplaceMenu';
import { CompareReplaceOptions } from 'src/features/CompareReplaceDialog/components/CompareReplaceOptions';
import { CompareReplaceRenameTemplateDialog } from 'src/features/CompareReplaceDialog/components/CompareReplaceRenameTemplateDialog';
import {
  CLIPBOARD_MENU_ITEM,
  getCopiedData,
  getIsSaveDisabled,
  getTemplateData,
  OPTIONS_MENU_ITEM,
} from 'src/features/CompareReplaceDialog/utils';
import { ClipboardData } from 'src/features/JobForm/components/CopyCompareButton/type';
import { FieldLayout } from 'src/pages/Job/JobContext';
import { useLocalStorage, useRouteParams } from 'src/utilities/hooks';
import { showSuccessMessage } from '../../utilities/notificationsService';

type CompareReplaceDialogProps = {
  compareDialogOpenSection: string;
  onClose: () => void;
  onConfirm: () => void;
  fieldLayout?: FieldLayout;
};

export function CompareReplaceDialog({
  compareDialogOpenSection,
  fieldLayout,
  onClose,
  onConfirm,
}: CompareReplaceDialogProps) {
  const { t } = useTranslation();
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isRenameDialogOpen, setIsRenameDialogOpen] = useState(false);
  const [clipboardData, setClipboardData] = useLocalStorage<ClipboardData>('clipboard', []);
  const { data: { values: options = {} } = {} } = useGetCompareReplaceOptionsQuery(
    {},
    { refetchOnMountOrArgChange: true },
  );

  const shouldSelectAllBlocksOption = options?.['clip.opt.checkall']?.value;
  const { jobType } = useRouteParams();

  const { data: templates = [] } = useGetCompareReplaceTemplatesQuery({
    src: jobType,
  });

  const { control, getValues, setValue, watch } = useForm({
    defaultValues: {
      templateConfigurationType: 'clipboard',
      templateNameNew: '',
      templateNameRename: '',
      templateNameUpdate: '',
    },
  });

  const { setValue: setFormValue } = useFormContext();
  const [
    createCompareReplaceTemplate,
    { isLoading: isCreateTemplateLoading, isSuccess: hasCreatedNewTemplate },
  ] = useCreateCompareReplaceTemplateMutation();

  const [deleteCompareReplaceTemplate, { isLoading: isDeleteTemplateLoading }] =
    useDeleteCompareReplaceTemplateMutation();

  const [
    updateCompareReplaceTemplate,
    { isLoading: isUpdateTemplateLoading, isSuccess: hasUpdatedTemplate },
  ] = useUpdateCompareReplaceTemplateMutation();
  const watchedFormData = watch();
  const { templateConfigurationType, templateNameNew, templateNameUpdate } = watchedFormData;

  const [selectedMenuItem, setSelectedMenuItem] = useState<string>(CLIPBOARD_MENU_ITEM);
  const shouldHideSameValuesOption = options?.['clip.opt.hidesame']?.value;

  const [isSaveTemplate, setIsSaveTemplate] = useState(false);

  const title = isSaveTemplate
    ? t('save_template', 'Save Template')
    : t('compare_and_replace', 'Compare and Replace');
  const isSaveDisabled = isSaveTemplate ? getIsSaveDisabled(watchedFormData) : false;

  function handleConfirmPaste() {
    const copiedData = getCopiedData(selectedMenuItem, clipboardData, templates);

    copiedData.forEach((copy) => {
      copy.fields.forEach((field) => {
        setFormValue(field.fieldId, field.value, { shouldDirty: true });
      });
    });

    onConfirm();
  }

  function handleOpenSaveTemplate() {
    setIsSaveTemplate(true);
  }

  function handleCancel() {
    isSaveTemplate ? setIsSaveTemplate(false) : onClose();
    setValue('templateConfigurationType', 'clipboard');
    setValue('templateNameNew', '');
    setValue('templateNameUpdate', '');
    setSelectedMenuItem(CLIPBOARD_MENU_ITEM);
  }

  async function handleSaveTemplate() {
    const templateData = getTemplateData(watchedFormData, clipboardData);
    const templateName = templateConfigurationType === 'new' ? templateNameNew : templateNameUpdate;
    const templateId = templates.find((template) => template.name === templateName)?.id;

    if (templateConfigurationType === 'clipboard') {
      setClipboardData(templateData);
      setIsSaveTemplate(false);
      setSelectedMenuItem(CLIPBOARD_MENU_ITEM);
    }

    if (templateConfigurationType === 'update' && templateId) {
      await updateCompareReplaceTemplate({
        name: templateName,
        sections: templateData,
        src: jobType,
        templateId,
      })
        .unwrap()
        .then((response) => {
          setSelectedMenuItem(templateName);
          showSuccessMessage(response.message);
        });
    }

    if (templateConfigurationType === 'new') {
      await createCompareReplaceTemplate({
        name: templateName,
        sections: templateData,
        src: jobType,
      })
        .unwrap()
        .then((response) => {
          setSelectedMenuItem(templateName);
          showSuccessMessage(response.message);
        });
    }

    setValue('templateConfigurationType', 'clipboard');
    setValue('templateNameUpdate', '');
    setValue('templateNameNew', '');
  }

  function handleConfirmOrSave() {
    isSaveTemplate ? handleSaveTemplate() : handleConfirmPaste();
  }

  useEffect(() => {
    if (hasCreatedNewTemplate || hasUpdatedTemplate) setIsSaveTemplate(false);
  }, [hasCreatedNewTemplate, hasUpdatedTemplate]);

  useEffect(() => {
    const selectedTemplateData = templates.find((template) => template.name === selectedMenuItem);
    const data =
      selectedMenuItem === CLIPBOARD_MENU_ITEM || selectedMenuItem === OPTIONS_MENU_ITEM
        ? clipboardData
        : selectedTemplateData?.val || [];

    const newDefaultValues = getDefaultValues(
      data,
      compareDialogOpenSection,
      shouldSelectAllBlocksOption,
    );

    for (const key in newDefaultValues) {
      setValue(key as any, newDefaultValues[key]);
    }
  }, [
    clipboardData,
    compareDialogOpenSection,
    shouldSelectAllBlocksOption,
    setValue,
    selectedMenuItem,
  ]);

  function handleOpenDeleteTemplate() {
    setIsDeleteDialogOpen(true);
  }

  function handleOpenRenameTemplate() {
    setIsRenameDialogOpen(true);
  }

  async function handleRenameTemplate() {
    const matchedTemplate = templates.find((template) => template.name === selectedMenuItem);

    await updateCompareReplaceTemplate({
      name: templateNameUpdate,
      sections: matchedTemplate?.val || [],
      src: jobType,
      templateId: matchedTemplate?.id,
    })
      .unwrap()
      .then((response) => {
        showSuccessMessage(response.message);
      });
    setIsRenameDialogOpen(false);
    setSelectedMenuItem(templateNameUpdate);
  }

  async function handleDeleteTemplate() {
    await deleteCompareReplaceTemplate({
      src: jobType,
      templateId: templates.find((template) => template.name === selectedMenuItem)?.id,
    })
      .unwrap()
      .then((response) => {
        showSuccessMessage(response.message);
      });
    setIsDeleteDialogOpen(false);
    setSelectedMenuItem(CLIPBOARD_MENU_ITEM);
  }

  function handleCloseDeleteTemplateDialog() {
    setIsDeleteDialogOpen(false);
  }

  function handleCloseRenameTemplateDialog() {
    setIsRenameDialogOpen(false);
    setValue('templateNameUpdate', '');
  }

  return (
    <>
      <Dialog fullWidth maxWidth="lg" open={!!compareDialogOpenSection}>
        <WaveDialogTitle onClose={onClose} title={title} />

        <Box component={DialogContent} display="flex" overflow="hidden">
          <Box component={Paper} display="flex" flex="1" overflow="hidden">
            {!isSaveTemplate && (
              <CompareReplaceMenu
                onSelectMenuItem={setSelectedMenuItem}
                selectedMenuItem={selectedMenuItem}
                templates={templates}
              />
            )}

            {selectedMenuItem !== OPTIONS_MENU_ITEM && (
              <CompareReplaceContent
                compareDialogOpenSection={compareDialogOpenSection}
                control={control}
                copiedData={getCopiedData(selectedMenuItem, clipboardData, templates)}
                fieldLayout={fieldLayout}
                getValues={getValues}
                isClipboard={selectedMenuItem === CLIPBOARD_MENU_ITEM}
                isSaveTemplate={isSaveTemplate}
                onOpenDeleteTemplate={handleOpenDeleteTemplate}
                onOpenRenameTemplate={handleOpenRenameTemplate}
                onOpenSaveTemplate={handleOpenSaveTemplate}
                setValue={setValue}
                shouldHideSameValuesOption={shouldHideSameValuesOption}
                shouldSelectAllBlocksOption={shouldSelectAllBlocksOption}
                templates={templates}
                watchedFormData={watchedFormData}
              />
            )}

            {selectedMenuItem === OPTIONS_MENU_ITEM && <CompareReplaceOptions options={options} />}
          </Box>
        </Box>

        <DialogActions>
          <Box display="flex" justifyContent="flex-end">
            <Box marginRight={2}>
              <Button color="warning" onClick={handleCancel}>
                <Trans i18nKey="lib.dialog.cancel">Cancel</Trans>
              </Button>
            </Box>

            <LoadingButton
              disabled={isSaveDisabled}
              loading={isCreateTemplateLoading || isUpdateTemplateLoading}
              onClick={handleConfirmOrSave}
            >
              {isSaveTemplate ? t('lib.ok', 'Save') : t('lib.confirm', 'Confirm')}
            </LoadingButton>
          </Box>
        </DialogActions>
      </Dialog>

      <CompareReplaceDeleteTemplateDialog
        isDeleteTemplateLoading={isDeleteTemplateLoading}
        isOpen={isDeleteDialogOpen}
        onCloseDeleteTemplateDialog={handleCloseDeleteTemplateDialog}
        onDeleteTemplate={handleDeleteTemplate}
        templateName={selectedMenuItem}
      />

      <CompareReplaceRenameTemplateDialog
        control={control}
        isOpen={isRenameDialogOpen}
        isRenameTemplateLoading={isUpdateTemplateLoading}
        onCloseRenameTemplateDialog={handleCloseRenameTemplateDialog}
        onRenameTemplate={handleRenameTemplate}
      />
    </>
  );
}
