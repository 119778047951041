import { useRef } from 'react';

import { SnackbarKey, SnackbarProvider } from 'notistack';

import { alpha, GlobalStyles, IconButton } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { WaveIcon } from '../../features/WaveIcon';

function SnackbarStyles() {
  const theme = useTheme();
  const transparent1 = alpha('#919EAB', 0.2);
  const transparent2 = alpha('#919EAB', 0.14);
  const transparent3 = alpha('#919EAB', 0.12);

  return (
    <GlobalStyles
      styles={{
        '#root': {
          '& .SnackbarContent-root': {
            '&.SnackbarItem-variantSuccess, &.SnackbarItem-variantError, &.SnackbarItem-variantWarning, &.SnackbarItem-variantInfo':
              {
                backgroundColor: theme.palette.background.paper,
                color: theme.palette.text.primary,
              },
            backgroundColor: theme.palette.grey[900],
            borderRadius: theme.shape.borderRadius,
            boxShadow: `0px 5px 5px -3px ${transparent1},0px 8px 10px 1px ${transparent2},0px 3px 14px 2px ${transparent3}`,
            color: '#FFFFFF',
            margin: theme.spacing(0.25, 0),
            padding: theme.spacing(1.5),
            width: '100%',
            [theme.breakpoints.up('md')]: {
              minWidth: 240,
            },
          },
          '& .SnackbarItem-action': {
            '& svg': { height: 20, width: 20 },
            color: theme.palette.action.active,
            marginRight: 0,
          },
          '& .SnackbarItem-message': {
            fontWeight: theme.typography.fontWeightMedium,
            padding: '0 !important',
          },
        },
      }}
    />
  );
}

export function Snackbar() {
  const notistackRef = useRef<SnackbarProvider>(null);

  function onClose(key: SnackbarKey) {
    return () => {
      if (notistackRef.current) {
        notistackRef.current.closeSnackbar(key);
      }
    };
  }

  return (
    <>
      <SnackbarStyles />

      <SnackbarProvider
        action={(key) => (
          <IconButton color="inherit" onClick={onClose(key)} size="small">
            <WaveIcon code="close" fontSize="small" />
          </IconButton>
        )}
        anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
        autoHideDuration={4000}
        dense
        hideIconVariant={true}
        maxSnack={5}
        ref={notistackRef}
        variant="success"
      />
    </>
  );
}
