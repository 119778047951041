import { ChangeEvent, useState } from 'react';

import { useTheme } from '@mui/material';

import { Common } from 'src/features/JobsTable/JobsTable';
import { useAppSelector } from 'src/utilities/hooks';

type UseJobsTableProps = Pick<Common, 'rows'>;

export function useJobsTable({ rows }: UseJobsTableProps) {
  const theme = useTheme();

  const [isDense, setIsDense] = useState(true);

  const userDeleteRights = useAppSelector((state) => state.user.rights.delete);

  const canTablePossiblyHaveADeletableRow = rows.some(({ jobType }) =>
    userDeleteRights.includes(`job-${jobType}`),
  );

  const subMenuItemsOfActiveJobs = useAppSelector((state) => state.user.mainMenu).find(
    ({ code }) => code === 'job',
  )?.subitems;
  const userRights = useAppSelector(state => state.user.rights);
  const canCopyToSomeJobType = 
    !!subMenuItemsOfActiveJobs?.length && 
      subMenuItemsOfActiveJobs.some(({ code }) => userRights.insert.includes(code));

  function handleChangeTableDensity(e: ChangeEvent<HTMLInputElement>) {
    setIsDense(e.target.checked);
  }

  return {
    canCopyToSomeJobType,
    canTablePossiblyHaveADeletableRow,
    handleChangeTableDensity,
    isDense,
    theme,
  };
}
