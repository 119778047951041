import i18n from 'i18next';
import { enqueueSnackbar, OptionsObject } from 'notistack';

function toast(message: string, options: OptionsObject = {}) {
  enqueueSnackbar(i18n.t(message), options);
}

export function showSuccessMessage(message: string, options: OptionsObject = {}) {
  toast(message, { ...options, variant: 'success' });
}

export function showErrorMessage(message: string, options: OptionsObject = {}) {
  toast(message, { ...options, variant: 'error' });
}

export function showInfoMessage(message: string, options: OptionsObject = {}) {
  toast(message, { ...options, variant: 'info' });
}

export function showWarningMessage(message: string, options: OptionsObject = {}) {
  toast(message, { ...options, variant: 'warning' });
}
