import { ChangeEvent, useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';

import {
  jobsTableApi,
  useDeleteJobMutation,
  useGetJobsQuery,
} from 'src/features/JobsTable/JobsTable.service';
import {
  useAppDispatch,
  useFirstRender,
  usePreference,
  usePreferencePrefix,
} from 'src/utilities/hooks';
import { usePreferenceAsNumber } from 'src/utilities/hooks/usePreferenceAsNumber';
import { showSuccessMessage } from '../../../../utilities/notificationsService';

export type BulkEditType = 'fields' | 'flags' | 'project' | 'status' | undefined;
export type IdsOfExpandedJobs = number[];
export type OpenConfirmationDialogParameters = { jobId: number; jobType: string };
export type SelectedRows = number[];

export type StatusChangeTask = {
  label: string;
  total: number;
};
export type StatusChangeTasks = StatusChangeTask[];

export type JobTask = {
  code: string;
  label: string;
  total: number;
};
export type JobTasks = JobTask[];

export function useJobs() {
  const { age, isAssignJobTable, isSubJobsTable, jobId, jobType, preferencePrefix } =
    usePreferencePrefix();
  const { jobType: unAlteredJobType } = useParams();

  const activeFiltersPreference = usePreference(`${preferencePrefix}.search`, []);
  const approvalTaskPreference = usePreference(`${preferencePrefix}.show-approval-tasks`, '1');
  const [areActiveFiltersVisible, setAreActiveFiltersVisible] = useState(true);
  const [bulkEditType, setBulkEditType] = useState<BulkEditType>();
  const columnsPreference = usePreference(`${preferencePrefix}.columns`, '');
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState(false);
  const [deleteJobId, setDeleteJobId] = useState(0);
  const [deleteJobType, setDeleteJobType] = useState('');
  const dispatch = useAppDispatch();
  const [areAllSubJobsExpanded, setAreAllSubJobsExpanded] = useState(false);
  const isFirstRender = useFirstRender();
  const [idsOfExpandedJobs, setIdsOfExpandedJobs] = useState<IdsOfExpandedJobs>([]);
  const jobTasksPreference = usePreference(`${preferencePrefix}.show-job-tasks`, '1');
  const [isMyBackupUserActive, setIsMyBackupUserActive] = useState(false);
  const orderByColumnAliasPreference = usePreference(`${preferencePrefix}.order`, 'jobid');
  const pagePreference = usePreferenceAsNumber({
    code: `${preferencePrefix}.listPage`,
    defaultValue: '0',
  });
  const [pageHistory, setPageHistory] = useState(pagePreference.value);
  const rowsPerPagePreference = usePreference(`${preferencePrefix}.rowsPerPage`, '25');
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedRows, setSelectedRows] = useState<SelectedRows>([]);
  const statusChangeTasksPreference = usePreference(
    `${preferencePrefix}.show-status-change-tasks`,
    '1',
  );
  const [dashboardUserId, setDashboardUserId] = useState(0);
  const [isUserIBackupActive, setIsUserIBackupActive] = useState(false);

  const [deleteJob, { isLoading: isJobBeingDeleted }] = useDeleteJobMutation();

  const isGlobalSearch = jobType === 'search';
  const isJobTypeDash = jobType === 'dash';

  const {
    data: jobs,
    isFetching: areJobsFetching,
    refetch: refetchJobs,
  } = useGetJobsQuery({
    age,
    endpoint: computeGetJobsEndpoint(),
    jobType,
    shouldIncludeArchiveStatus: isSubJobsTable,
    ...(isJobTypeDash && dashboardUserId !== 0 && { dashboardUserId }),
    ...(isAssignJobTable && jobId && { parentJobId: jobId, parentJobType: unAlteredJobType }),
  });

  function computeGetJobsEndpoint() {
    if (isJobTypeDash) return '/dashboard';

    if (isGlobalSearch) return '/globalsearch';

    if (isSubJobsTable) return `jobs/${jobId}/subjobs`;

    return '/jobs/all';
  }

  const columns = jobs?.columns || [];
  const deadlines = jobs?.deadlines || {};
  const jobIdsWithSubJobs = jobs?.jobIdsWithSubJobs || [];
  const rows = (jobs?.rows || []).filter((row) =>
    Object.values(row).some((column) =>
      column?.toString().toLowerCase().includes(searchTerm.toLowerCase()),
    ),
  );
  const totalRowCount = jobs?.count || 0;
  const approvalTasks = (jobs?.tasks.approvalTasks || []) as unknown as JobTasks;
  const jobTasks = (jobs?.tasks.jobTasks || []) as unknown as JobTasks;
  const statusChangeTasks = (jobs?.tasks.statusChangeTasks || []) as unknown as StatusChangeTasks;

  async function handleDeleteJob() {
    deleteJob({
      age,
      jobId: deleteJobId,
      jobType: deleteJobType,
    })
      .unwrap()
      .then(({ message }) => {
        showSuccessMessage(message);
        setIsConfirmationDialogOpen(false);
      });
  }

  function handleBulkEditSuccess() {
    dispatch(jobsTableApi.util.invalidateTags(['Jobs']));
    setBulkEditType(undefined);
    setSelectedRows([]);
    showSuccessMessage('All jobs were updated successfully.');
  }

  function handleCancelBulkEdit() {
    setBulkEditType(undefined);
    setSelectedRows([]);
  }

  function handleChangePageSearch({
    target: { value: searchTerm },
  }: ChangeEvent<HTMLInputElement>) {
    setSearchTerm(searchTerm);
  }

  function handleClickRowCheckbox(rowId: number) {
    setSelectedRows((previousSelectedRows) =>
      previousSelectedRows.includes(rowId)
        ? previousSelectedRows.filter((row) => row !== rowId)
        : [...previousSelectedRows, rowId],
    );
  }

  function handleClickRowRadio(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    event.preventDefault();
    event.stopPropagation();
    setSelectedRows([parseInt((event.target as HTMLInputElement).value)]);
  }

  function handleCloseConfirmationDialog() {
    setIsConfirmationDialogOpen(false);
    setDeleteJobId(0);
    setDeleteJobType('');
  }

  function handleToggleActiveFiltersVisibility() {
    setAreActiveFiltersVisible(
      (arePreviousActiveFiltersVisible) => !arePreviousActiveFiltersVisible,
    );
  }

  function handleToggleAreAllSubJobsExpanded() {
    setAreAllSubJobsExpanded(!areAllSubJobsExpanded);
    setIdsOfExpandedJobs(areAllSubJobsExpanded ? [] : jobIdsWithSubJobs);
  }

  function handleToggleSelectAllRows({
    target: { checked: isChecked },
  }: ChangeEvent<HTMLInputElement>) {
    setSelectedRows(
      isChecked
        ? rows.map(({ jobId }) => (typeof jobId === 'string' ? parseInt(jobId) : (jobId as number)))
        : [],
    );
  }

  function handleOpenConfirmationDialog({ jobId, jobType }: OpenConfirmationDialogParameters) {
    setIsConfirmationDialogOpen(true);
    setDeleteJobId(jobId);
    setDeleteJobType(jobType);
  }

  useEffect(() => {
    // Clear searchTerm and selectedRows
    // because when activeFiltersPreference changes you will have a new page of results.
    setSearchTerm('');
    setSelectedRows([]);
  }, [activeFiltersPreference.value]);

  useEffect(() => {
    refetchJobs();
  }, [
    activeFiltersPreference.value,
    approvalTaskPreference.value,
    columnsPreference.value,
    dashboardUserId,
    jobTasksPreference.value,
    isMyBackupUserActive,
    isUserIBackupActive,
    orderByColumnAliasPreference.value,
    pagePreference.value,
    rowsPerPagePreference.value,
    statusChangeTasksPreference.value,
  ]);

  useEffect(() => {
    // If this is ran on first render, then page preference is always set to 0.
    if (!isFirstRender) {
      pagePreference.set('0');
      setSelectedRows([]);
    }
  }, [
    approvalTaskPreference.value,
    jobTasksPreference.value,
    isMyBackupUserActive,
    statusChangeTasksPreference.value,
    isUserIBackupActive,
  ]);

  useEffect(() => {
    setSelectedRows([]);

    if (dashboardUserId !== 0) {
      pagePreference.set('0');
      if (!activeFiltersPreference.value.length) setPageHistory(pagePreference.value);
    } else pagePreference.set(pageHistory.toString());
  }, [dashboardUserId]);

  return {
    approvalTasks,
    areActiveFiltersVisible,
    areAllSubJobsExpanded,
    areJobsFetching,
    bulkEditType,
    columns,
    dashboardUserId,
    deadlines,
    deleteJobId,
    handleBulkEditSuccess,
    handleCancelBulkEdit,
    handleChangePageSearch,
    handleClickRowCheckbox,
    handleClickRowRadio,
    handleCloseConfirmationDialog,
    handleDeleteJob,
    handleOpenConfirmationDialog,
    handleToggleActiveFiltersVisibility,
    handleToggleAreAllSubJobsExpanded,
    handleToggleSelectAllRows,
    idsOfExpandedJobs,
    isConfirmationDialogOpen,
    isJobBeingDeleted,
    isMyBackupUserActive,
    isUserIBackupActive,
    jobIdsWithSubJobs,
    jobTasks,
    jobType,
    rows,
    searchTerm,
    selectedRows,
    setAreActiveFiltersVisible,
    setAreAllSubJobsExpanded,
    setBulkEditType,
    setDashboardUserId,
    setIdsOfExpandedJobs,
    setIsMyBackupUserActive,
    setIsUserIBackupActive,
    setPageHistory,
    setSelectedRows,
    statusChangeTasks,
    totalRowCount,
  };
}
