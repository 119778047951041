import { SyntheticEvent } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useGallery } from 'react-photoswipe-gallery';

import { Avatar, Box, IconButton, Typography } from '@mui/material';

import { useLazyGetDownloadUrlQuery } from 'src/features/JobFiles/JobFiles.service';
import { WaveIcon } from 'src/features/WaveIcon';
import {
  StyledButton,
  StyledChip,
  StyledDrawer,
} from 'src/pages/FileSearch/FileSearchSelectionDrawer/styled';
import { FileSearchSelectedData, FileSearchStateType } from 'src/store/fileSearchSlice';
import { useAppSelector } from 'src/utilities/hooks';
import { showSuccessMessage } from '../../../utilities/notificationsService';

import defaultThumbnail from '../../../images/thumbnails/default_thumbnail.png';

type FileSearchSelectionDrawerProps = {
  isSelectionDrawerOpen: boolean;
  selected: FileSearchStateType;
  onDeselect: (selectedData: FileSearchSelectedData) => void;
  width: number;
  onCloseSelectionDrawer: () => void;
};

export function FileSearchSelectionDrawer({
  isSelectionDrawerOpen,
  onCloseSelectionDrawer,
  onDeselect,
  selected,
  width,
}: FileSearchSelectionDrawerProps) {
  const [downloadFiles] = useLazyGetDownloadUrlQuery();
  const userId = useAppSelector((state) => state.user.details.id);
  const { open } = useGallery();
  const { t } = useTranslation();

  function handleDownloadFiles() {
    const selectedFiles = selected.map((selectedData: FileSearchSelectedData) => selectedData[0]);

    downloadFiles({
      fileIds: selectedFiles,
      userId,
    })
      .unwrap()
      .then(({ url }) => {
        window.open(`${import.meta.env.VITE_API}${url}`);

        showSuccessMessage(t('download_successful_content', 'Download was successful'));
      });
  }

  function handleOpenSelectedGallery() {
    open(0);
  }

  function handleImageError(event: SyntheticEvent<HTMLImageElement, Event>) {
    const target = event.target as HTMLImageElement;

    target.src = defaultThumbnail;
  }

  return (
    <StyledDrawer anchor="bottom" open={isSelectionDrawerOpen} variant="persistent" width={width}>
      <Box alignItems="center" display="flex" justifyContent="space-between" marginBottom={2}>
        <Typography variant="h6">{`${t('file-ser.selected_files', 'Selected Files')} (${
          selected.length
        }) `}</Typography>

        <IconButton onClick={onCloseSelectionDrawer}>
          <WaveIcon code="close" />
        </IconButton>
      </Box>

      <Box flex="1" overflow="scroll">
        {selected.map((selectedData: FileSearchSelectedData) => {
          const [fileId, jobID, thumb] = selectedData;

          return (
            <StyledChip
              avatar={
                <Avatar imgProps={{ onError: handleImageError }} src={thumb || defaultThumbnail} />
              }
              key={fileId}
              label={jobID}
              onDelete={() => onDeselect(selectedData)}
            />
          );
        })}
      </Box>

      <Box display="flex" justifyContent="flex-end" marginTop={2}>
        <StyledButton
          onClick={handleDownloadFiles}
          startIcon={<WaveIcon code="cloud-download" />}
          variant="outlined"
        >
          <Trans i18nKey="lib.file.download">Download</Trans>
        </StyledButton>

        <StyledButton
          onClick={handleOpenSelectedGallery}
          startIcon={<WaveIcon code="photo-library" />}
          variant="outlined"
        >
          <Trans i18nKey="file-ser.view_in_gallery">View in gallery</Trans>
        </StyledButton>
      </Box>
    </StyledDrawer>
  );
}
