import { useEffect } from 'react';

import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import { changeLanguage } from 'i18next';

import { Snackbar } from 'src/components/Snackbar';
import { WaveTheme } from 'src/components/WaveTheme';
import { ProjectAssign } from './components/ProjectAssign';
import { SecureZone } from './components/SecureZone';
import { ExternalRevisor } from './features/ExternalRevisor';
import { AssignToProject } from './pages/AssignToProject';
import { CompareFiles } from './pages/CompareFiles';
import { Dashboard } from './pages/Dashboard';
import { FileSearch } from './pages/FileSearch';
import { GeneralFiles } from './pages/GeneralFiles';
import { GeneralHelp } from './pages/GeneralHelp';
import { GenericIFrame } from './pages/GenericIFrame';
import { Job } from './pages/Job';
import { Jobs } from './pages/Jobs';
import { Login } from './pages/Login';
import { NotFound } from './pages/NotFound';
import { Reporting } from './pages/Reporting';
import { UpdatePassword } from './pages/UpdatePassword';
import { UserProfile } from './pages/UserProfile';
import { UserSettings } from './pages/UserSettings';
import { Viewer } from './pages/Viewer';
import { useAppSelector, useI18Initialized } from './utilities/hooks';

export function App() {
  const isInitialized = useI18Initialized();
  const userLanguage = useAppSelector((state) => state.user.preferences['sys.lang']?.value);
  const rights = useAppSelector((state) => state.user.rights);
  const path = useLocation().pathname.split('/');
  const [, age, jobType] = path[1].split('-');
  const jobId = path[2];
  const jobTab = path[3];
  let redirectPage = '';
  let missingRights = '';
  const language = userLanguage || 'en';

  function checkAgeRights(): boolean {
    if (['hom', 'job'].includes(age)) return true;

    if (age === 'global') {
      if (rights.read.includes('ser')) return true;

      missingRights = 'ser';

      return false;
    }

    if (rights.read.includes(age)) return true;

    redirectPage = '/jobs-hom-dash';
    missingRights = age;

    return false;
  }

  function checkJobTypeRights(): boolean {
    if (!checkAgeRights()) return false;

    if (['dash', 'search'].includes(jobType) || rights.read.includes(`job-${jobType}`)) return true;

    redirectPage = '/jobs-hom-dash';
    missingRights = `job-${jobType}`;

    return false;
  }

  function checkJobTabRights(): boolean {
    if (!checkJobTypeRights()) return false;

    const jobTabRight =
      jobTab === 'assign' ? 'pro-assign' : jobTab === 'sub-jobs' ? `${jobType}-sub` : jobTab;

    if (
      ['job', 'det'].includes(jobTab) ||
      rights.read.includes(`job-${jobTabRight}`) ||
      rights.read.includes(`job-${jobType}-${jobTabRight}`)
    ) {
      return true;
    }

    redirectPage = `/jobs-${age}-${jobType}/${jobId}/job`;
    missingRights = `job-${jobTabRight}`;

    return false;
  }

  useEffect(() => {
    if (isInitialized) {
      void changeLanguage(language);
      localStorage.setItem('userLanguage', language);
    }
  }, [isInitialized]);

  if (!isInitialized) {
    return null;
  }

  return (
    <WaveTheme>
      <Snackbar />

      <Routes>
        <Route element={<SecureZone />}>
          <Route
            element={
              checkJobTypeRights() ? (
                age === 'hom' ? (
                  <Dashboard />
                ) : (
                  <Jobs />
                )
              ) : (
                <Navigate replace state={{ missingRights: missingRights }} to={redirectPage} />
              )
            }
            path="/jobs-:age-:jobType"
          />

          <Route
            element={<Navigate replace to={`/jobs-${age}-${jobType}/${jobId}/job`} />}
            path="/jobs-:age-:jobType/:jobId"
          />

          <Route
            element={
              jobTab === 'compare' ? (
                <CompareFiles />
              ) : checkJobTabRights() ? (
                jobTab === 'assign' && jobId !== 'project-bulk-edit' ? (
                  <AssignToProject />
                ) : jobId === 'project-bulk-edit' ? (
                  <ProjectAssign />
                ) : (
                  <Job />
                )
              ) : jobTab === 'viewer' ? (
                <Viewer />
              ) : (
                <Navigate replace state={{ missingRights: missingRights }} to={redirectPage} />
              )
            }
            path="/jobs-:age-:jobType/:jobId/:tab"
          />

          <Route
            element={
              checkJobTabRights() ? (
                <Job />
              ) : (
                <Navigate replace state={{ missingRights: missingRights }} to={redirectPage} />
              )
            }
            path="/jobs-:age-:jobType/:jobId/:tab/:type"
          />

          <Route
            element={
              rights.read.includes(`menu-${age}`) ? (
                <GenericIFrame />
              ) : (
                <Navigate replace state={{ missingRights: `menu-${age}` }} to="/jobs-hom-dash" />
              )
            }
            path="/additional-:tabCode"
          />

          <Route element={<Reporting />} path="/reporting" />

          <Route element={<GeneralHelp />} path="/general-help" />

          <Route element={<GeneralFiles />} path="/general-files" />

          <Route element={<UserProfile />} path="/user-profile" />

          <Route element={<UserSettings />} path="/user-settings" />

          <Route element={<FileSearch />} path="/file-search" />
        </Route>

        <Route element={<Navigate replace to="/jobs-hom-dash" />} path="/" />

        <Route element={<Login />} path="/login" />

        <Route element={<UpdatePassword />} path="/update-password/:secretKey" />

        <Route
          element={<ExternalRevisor />}
          path="external-revisor/:token/jobs-:age-:jobType/:jobId"
        />

        <Route element={<NotFound />} path="/*" />
      </Routes>
    </WaveTheme>
  );
}
