import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { SerializedError } from '@reduxjs/toolkit';

import { BulkEditJobFieldsProps } from 'src/features/BulkEditJobFields/BulkEditJobFields';
import {
  ActionBarCode,
  useGetActionBarAndFieldsQuery,
} from 'src/features/BulkEditJobFields/BulkEditJobFields.service';
import {
  ASSOCIATED_CHECKBOX_PREFIX,
  computeAssociatedCheckboxAlias,
  isAnAssociatedCheckbox,
} from 'src/features/BulkEditJobFields/utilities';
import { UpdateJobTransformed, useUpdateJobMutation } from 'src/features/JobForm/JobForm.service';
import { FieldValues, JobIdValue } from 'src/pages/Job/Job.service';
import { computeDefaultFieldValueByType } from 'src/pages/Job/utilities/helperFunctions';
import { filterFormData } from 'src/utilities/helperFunctions';
import { useRouteParams, useWindowDimensions } from 'src/utilities/hooks';
import { showErrorMessage } from '../../utilities/notificationsService';

import { ApiError } from 'src/utilities/types';

type DirtyFields = Partial<
  Readonly<{
    [x: string]: any;
  }>
>;
type JobIds = JobIdValue[];
type Responses = (
  | {
      data: UpdateJobTransformed;
    }
  | {
      error: ApiError | SerializedError;
    }
)[];

const navbarHeight = 64;

export function useBulkEditJobFields({
  onBulkEditSuccess,
  onCancelBulkEdit,
  selectedRows,
}: BulkEditJobFieldsProps) {
  const { jobType } = useRouteParams();

  const [activeStep, setActiveStep] = useState(0);
  const [updateJob] = useUpdateJobMutation();

  const { data } = useGetActionBarAndFieldsQuery({ jobType });
  const actionBar = data?.actionBar;
  const fields = data?.fields;

  const useFormReturn = useForm();
  const {
    formState: { dirtyFields },
    handleSubmit: reactHookFormHandleSubmit,
    reset,
  } = useFormReturn;

  const actionBarHeight = document.getElementById('actionBar')?.offsetHeight ?? 0;
  const { height: windowHeight } = useWindowDimensions();
  const contentAreaHeight = windowHeight - navbarHeight - actionBarHeight;

  const codesOfButtonsThatShouldBeVisible =
    activeStep === 0 ? ['cancel', 'next'] : ['cancel', 'back', 'confirm'];

  function handleClickActionBarButton(code: ActionBarCode) {
    if (code === 'cancel') onCancelBulkEdit();
    else if (code === 'next') handleClickNext();
    else if (code === 'back') handleClickBack();
  }

  function handleClickNext() {
    setActiveStep((previousActiveStep) => previousActiveStep + 1);
  }

  function handleClickBack() {
    setActiveStep((previousActiveStep) => previousActiveStep - 1);
  }

  async function handleSubmit(formData: FieldValues) {
    const { jobFields, jobIds } = separateAssociatedCheckboxesJobFieldsAndJobIds(formData);
    const transformedDirtyFields = transformAssociatedDirtyCheckboxToDirtyField(dirtyFields);
    const filteredFormData: FieldValues = filterFormData(transformedDirtyFields, fields, jobFields);

    await updateJobs(jobIds, filteredFormData);
  }

  function separateAssociatedCheckboxesJobFieldsAndJobIds(formData: FieldValues) {
    const associatedCheckboxes: FieldValues = {};
    const jobFields: FieldValues = {};
    const jobIds: JobIds = [];
    const numberOfCharactersInTheWordJobId = 5;

    for (const alias in formData) {
      if (isAnAssociatedCheckbox(alias)) associatedCheckboxes[alias] = formData[alias];
      else if (alias.includes('jobId')) jobIds.push(alias.slice(numberOfCharactersInTheWordJobId));
      else jobFields[alias] = formData[alias];
    }

    return { associatedCheckboxes, jobFields, jobIds };
  }

  function transformAssociatedDirtyCheckboxToDirtyField(dirtyFields: DirtyFields) {
    const transformedDirtyFields: DirtyFields = {};

    for (const alias in dirtyFields) {
      if (isAnAssociatedCheckbox(alias)) {
        const associatedFieldAlias = alias.replace(ASSOCIATED_CHECKBOX_PREFIX, '');

        transformedDirtyFields[associatedFieldAlias] = true;
      } else transformedDirtyFields[alias] = true;
    }

    return transformedDirtyFields;
  }

  async function updateJobs(jobIds: JobIds, filteredFormData: FieldValues) {
    return await Promise.all(
      jobIds.map((jobId) =>
        updateJob({
          formValues: filteredFormData,
          isBulkEdit: true,
          jobId,
          jobType,
        }),
      ),
    ).then((responses) => {
      const jobIdsOfFailedToUpdateJobs = computeJobIdsOfFailedToUpdateJobs(responses, jobIds);

      if (jobIdsOfFailedToUpdateJobs.length) displayAnErrorMessage(jobIdsOfFailedToUpdateJobs);
      else onBulkEditSuccess();
    });
  }

  function computeJobIdsOfFailedToUpdateJobs(responses: Responses, jobIds: JobIds) {
    const jobIdsOfSuccessfullyUpdateJobs: JobIds = [];

    responses.forEach((response) => {
      if ('data' in response) jobIdsOfSuccessfullyUpdateJobs.push(response.data.jobId);
    });

    return jobIds.filter((jobId) => !jobIdsOfSuccessfullyUpdateJobs.includes(jobId));
  }

  function displayAnErrorMessage(jobIdsOfFailedToUpdateJobs: JobIds) {
    showErrorMessage(
      `The jobs with the following job IDs failed to update: ${jobIdsOfFailedToUpdateJobs.join()}`,
    );
  }

  useEffect(() => {
    if (!fields) return;

    const defaultValues = {} as FieldValues;

    Object.values(fields).forEach(({ alias, type }) => {
      const associatedCheckboxAlias = computeAssociatedCheckboxAlias(alias);

      defaultValues[associatedCheckboxAlias] = false;
      defaultValues[alias] = computeDefaultFieldValueByType(type);
    });

    selectedRows.forEach((rowId) => {
      defaultValues[`jobId${rowId}`] = true;
    });

    reset(defaultValues);
  }, [fields]);

  return {
    actionBar,
    activeStep,
    codesOfButtonsThatShouldBeVisible,
    contentAreaHeight,
    fields,
    handleClickActionBarButton,
    onSubmit: reactHookFormHandleSubmit(handleSubmit),
    useFormReturn,
  };
}
