import _ from 'lodash';

import {
  ContentCategoriesList,
  ContentItem,
  ContentList,
  JobContentItem,
  JobContentList,
} from './Content.types';

function containsAllElements(source: string[], target: string[]) {
  return target.every((element) => source.includes(element));
}

export function getCategoriesList(
  categoriesList: ContentCategoriesList[],
  getCurrentlySelectedLayouts: (categoryId: string) => string[],
) {
  return categoriesList
    .filter((category) => {
      const currentlySelectedLayouts = getCurrentlySelectedLayouts(category.code);

      return !containsAllElements(currentlySelectedLayouts, Object.keys(category.layouts));
    })
    .map((category) => {
      const currentlySelectedLayouts = getCurrentlySelectedLayouts(category.code);

      return {
        ...category,
        layouts: Object.fromEntries(
          Object.entries(category.layouts).filter(
            ([key]) => !currentlySelectedLayouts.includes(key),
          ),
        ),
      };
    });
}

export function transformContentListResponse(response: ContentList | undefined) {
  const result: Record<string, ContentList> = {};

  response?.forEach((data: ContentItem) => {
    const existingContent = result[data.Metadata.Category];
    const contentItem = {
      Language: data.Language,
      Metadata: data.Metadata,
      Status: data.Status,
      Text: data.Text,
    };

    if (existingContent) {
      existingContent.push(contentItem);
    } else {
      result[data.Metadata.Category] = [contentItem];
    }
  });

  return result;
}

function addCategoryGroupIntoContent(content: JobContentItem) {
  return {
    ...content,
    category_group: `category-${content.category_id}`,
  };
}

export function setContentFormInitialValues(
  contentList: JobContentList | undefined,
  translations: string[] | undefined,
  categoriesList: ContentCategoriesList[],
) {
  const payload = {
    categories: [{ amount: undefined, categoryId: '', items: [{ status: 'draft' }], layout: '' }],
    initialSetupComplete: false,
    template: 'My Test Template',
  };

  if (contentList && contentList.length) {
    const categories: any[] = [];

    const jobContentsByCategory = _.groupBy(
      contentList?.map(addCategoryGroupIntoContent),
      'category_group',
    );

    Object.keys(jobContentsByCategory).map((categoryId) => {
      const contents = jobContentsByCategory[categoryId];
      let layout;
      const items = contents.map((content) => {
        layout = content.layout;

        return {
          layout: content.layout,
          result: content.content,
          status: content.status,
          translations: {},
        };
      });
      const contentCategoryId = categoriesList.find(
        (category) => category.id === Number(categoryId.replace(/category-/, '')),
      )?.code;
      const category = {
        amount: contents.length,
        categoryId: contentCategoryId,
        items,
        layout,
      };

      categories.push(category);
    });

    categories.push({
      amount: undefined,
      categoryId: '',
      items: [{ status: 'draft' }],
      layout: '',
    });

    payload.categories = categories;
  }

  return payload;
}

export function setContentListInitialValues(
  contentList: JobContentItem[],
  key: keyof JobContentItem,
) {
  if (!contentList || contentList.length === 0) {
    return contentList;
  }

  const occurrenceCount: { [key: string]: any } = {};

  contentList.forEach((item) => {
    const value = item[key];

    occurrenceCount[value] = (occurrenceCount[value] || 0) + 1;
  });

  const result: JobContentItem[] = [];
  const sequentialCount: any = {};

  contentList.forEach((item) => {
    const value = item[key];

    sequentialCount[value] = (sequentialCount[value] || 0) + 1;

    result.push({
      ...item,
      index: sequentialCount[value],
      totalCount: occurrenceCount[value],
    });
  });

  return result;
}

export function transformJobContentToRemove(contents: JobContentList, contentIds: number[]) {
  if (contents.length === contentIds.length) {
    return [];
  }

  const updatedList = _.groupBy(
    contents
      ?.filter((item) => !contentIds.includes(item.content_id))
      ?.map(addCategoryGroupIntoContent),
    'category_group',
  );

  const jobContentList: JobContentItem[] = [];

  Object.keys(updatedList).map((groupKey, groupIndex) => {
    const items = updatedList[groupKey];

    items.map((item, itemIndex) =>
      jobContentList.push({
        ...item,
        features: {
          ntn: item.features?.ntn ? item.features?.ntn : [],
          packtypes: item.features?.packtypes ? item.features?.packtypes : [],
        },
        position: [groupIndex + 1, itemIndex + 1, 1],
      }),
    );
  });

  return jobContentList;
}
