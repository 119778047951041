import { Typography } from '@mui/material';

import { PhraseContent, PhraseContentMessage } from './HistoryTableRow';

type TProps = {
  message: PhraseContentMessage;
};

export function PhraseEdit({ message }: TProps) {
  const contents: PhraseContent[] = [];

  Object.keys(message).forEach((item) => {
    const content = message[item];

    if (content.value.length > 0) {
      contents.push({ ...content, category: item });
    }
  });

  function formatMessage(message: string, status: string) {
    const contentStatus: { [key: string]: string } = {
      content_added: 'has been added',
      content_removed: 'has been removed',
      content_updated: 'has been updated',
    };
    // eslint-disable-next-line no-useless-escape
    const parts = message.split(/(?:\"([^\"]*)\")/).filter(Boolean);

    if (parts.length === 2) {
      return (
        <Typography variant="body2">
          - {`"${parts[0]}" ( ${parts[1]} ) ${contentStatus[status]}`}
        </Typography>
      );
    }

    if (parts.length === 1) {
      <Typography variant="body2">- {`"${parts[0]}"`}</Typography>;
    }

    return '';
  }

  return (
    <div>
      {contents.map((content, index) => (
        <Typography component="div" key={index}>
          <Typography
            sx={{ fontSize: '16px', fontWeight: 600, marginBottom: '5px', marginTop: '5px' }}
          >
            {content.title}
          </Typography>

          {content.value.map((value) => formatMessage(value, content.category as string))}
        </Typography>
      ))}
    </div>
  );
}
