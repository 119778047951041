import { memo } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { useNavigate } from 'react-router-dom';

import { Box, Grid } from '@mui/material';

import { Typography } from 'src/components/Typography';
import { useLazyGetDownloadUrlQuery } from 'src/features/JobFiles/JobFiles.service';
import { WaveIcon } from 'src/features/WaveIcon';
import { FileColumnMappingsEnum } from 'src/pages/FileSearch/enums/FileColumnMappings';
import { JobDetailsColumnMappingsEnum } from 'src/pages/FileSearch/enums/JobDetailsColumnMappings';
import { FileDataType } from 'src/pages/FileSearch/FileSearch.service';
import {
  getBytesToMegabytes,
  getFormattedDate,
} from 'src/pages/FileSearch/FileSearchGridItem/utils';
import {
  StyledButton,
  StyledInfoSection,
  StyledInfoSectionHeader,
  StyledTypography,
} from 'src/pages/FileSearch/FileSearchInfoSection/styled';
import { StyledTypographyWhiteSpace } from 'src/pages/FileSearch/styled';
import { getFilteredRowLabelsAndData, getNavigationUrl } from 'src/pages/FileSearch/utils';
import { useAppSelector } from 'src/utilities/hooks';
import { showSuccessMessage } from '../../../utilities/notificationsService';

export type FileSearchInfoSectionProps = {
  shouldDisplaySideBar: boolean;
  shouldStartAnimation: boolean;
  fileItem: FileDataType;
  isExpanded: boolean;
  onClick: () => void;
};

function FileSearchInfoSection({
  fileItem,
  isExpanded,
  onClick,
  shouldDisplaySideBar,
  shouldStartAnimation,
}: FileSearchInfoSectionProps) {
  const [downloadFiles] = useLazyGetDownloadUrlQuery();
  const userId = useAppSelector((state) => state.user.details.id);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const {
    BRAND,
    CATEGORY,
    FILE_NAME,
    FILE_SIZE,
    JOB_ID,
    KEYWORDS,
    LAST_UPDATED,
    PACK_CODE,
    PRINT_METHOD,
    PRINTER_NAME,
    PRODUCT_NAME,
    PROJECT_NAME,
    SUBBRAND,
    VERSION,
  } = getFilteredRowLabelsAndData(fileItem, [
    FileColumnMappingsEnum.LAST_UPDATED,
    FileColumnMappingsEnum.JOB_ID,
    FileColumnMappingsEnum.FILE_NAME,
    FileColumnMappingsEnum.FILE_SIZE,
    FileColumnMappingsEnum.VERSION,
    JobDetailsColumnMappingsEnum.KEYWORDS,
    JobDetailsColumnMappingsEnum.PROJECT_NAME,
    JobDetailsColumnMappingsEnum.BRAND,
    JobDetailsColumnMappingsEnum.SUBBRAND,
    JobDetailsColumnMappingsEnum.PRODUCT_NAME,
    JobDetailsColumnMappingsEnum.PACK_CODE,
    JobDetailsColumnMappingsEnum.CATEGORY,
    JobDetailsColumnMappingsEnum.PRINTER_NAME,
    JobDetailsColumnMappingsEnum.PRINT_METHOD,
  ]);

  const sizeInMegaBytes = getBytesToMegabytes(FILE_SIZE.value);
  const dataFormatted = getFormattedDate(LAST_UPDATED.value);

  function handleOpenJobFilesTab() {
    const src = fileItem.src;
    const tableName = fileItem.job.db;
    const jobId = fileItem.jobid.toString();

    const url = getNavigationUrl({ jobId, src, tableName });

    navigate(url);
  }

  function handleDownloadFiles() {
    downloadFiles({
      fileIds: [fileItem?.id],
      userId,
    })
      .unwrap()
      .then(({ url }) => {
        window.open(`${import.meta.env.VITE_API}${url}`);

        showSuccessMessage(t('download_successful_content', 'Download was successful'));
      });
  }

  return (
    <StyledInfoSection
      isExpanded={isExpanded}
      shouldDisplaySideBar={shouldDisplaySideBar}
      shouldStartAnimation={shouldStartAnimation}
    >
      <StyledInfoSectionHeader onClick={onClick}>
        <Box>
          <Typography fontWeight={600}>{String(JOB_ID.value)}</Typography>

          <Typography variant="subtitle2">{dataFormatted}</Typography>
        </Box>

        <Box alignItems="center" display="flex">
          <Typography>
            {isExpanded
              ? t('file-ser.hide-information', 'Hide information')
              : t('file-ser.show_information', 'Show information')}
          </Typography>

          <WaveIcon code={isExpanded ? 'expand-more' : 'expand-less'} fontSize="medium" />
        </Box>
      </StyledInfoSectionHeader>

      <Box alignItems="start" display="flex">
        <Box borderTop="1px solid white" display="flex" flex="1" height="100%" paddingTop={1}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <StyledTypographyWhiteSpace fontWeight="700">
                <Trans i18nKey={FILE_NAME.translationCode}>{FILE_NAME.label}:</Trans>
              </StyledTypographyWhiteSpace>

              <StyledTypography gutterBottom>{FILE_NAME.value}</StyledTypography>

              <StyledTypographyWhiteSpace fontWeight="700">
                <Trans i18nKey={KEYWORDS.translationCode}>{KEYWORDS.label}:</Trans>
              </StyledTypographyWhiteSpace>

              <StyledTypography gutterBottom>{KEYWORDS.value}</StyledTypography>

              <StyledTypographyWhiteSpace fontWeight="700">
                <Trans i18nKey={PROJECT_NAME.translationCode}>{PROJECT_NAME.label}:</Trans>
              </StyledTypographyWhiteSpace>

              <StyledTypography gutterBottom>{PROJECT_NAME.value}</StyledTypography>

              <StyledTypographyWhiteSpace fontWeight="700">
                <Trans i18nKey={VERSION.translationCode}>{VERSION.label}:</Trans>
              </StyledTypographyWhiteSpace>

              <StyledTypography gutterBottom>{VERSION.value}</StyledTypography>
            </Grid>

            <Grid item xs={4}>
              <StyledTypographyWhiteSpace fontWeight="700">
                <Trans i18nKey={FILE_SIZE.translationCode}>{FILE_SIZE.label}:</Trans>
              </StyledTypographyWhiteSpace>

              <StyledTypography gutterBottom>{sizeInMegaBytes}</StyledTypography>

              <StyledTypographyWhiteSpace fontWeight="700">
                <Trans i18nKey={BRAND.translationCode}>{BRAND.label}:</Trans>
              </StyledTypographyWhiteSpace>

              <StyledTypography gutterBottom>{BRAND.value}</StyledTypography>

              <StyledTypographyWhiteSpace fontWeight="700">
                <Trans i18nKey={SUBBRAND.translationCode}>{SUBBRAND.label}:</Trans>
              </StyledTypographyWhiteSpace>

              <StyledTypography gutterBottom>{SUBBRAND.value}</StyledTypography>

              <StyledTypographyWhiteSpace fontWeight="700">
                <Trans i18nKey={PRODUCT_NAME.translationCode}>{PRODUCT_NAME.label}:</Trans>
              </StyledTypographyWhiteSpace>

              <StyledTypography gutterBottom>{PRODUCT_NAME.value}</StyledTypography>
            </Grid>

            <Grid item xs={4}>
              <StyledTypographyWhiteSpace fontWeight="700">
                <Trans i18nKey={PACK_CODE.translationCode}>{PACK_CODE.label}:</Trans>
              </StyledTypographyWhiteSpace>

              <StyledTypography gutterBottom>{PACK_CODE.value}</StyledTypography>

              <StyledTypographyWhiteSpace fontWeight="700">
                <Trans i18nKey={CATEGORY.translationCode}>{CATEGORY.label}:</Trans>
              </StyledTypographyWhiteSpace>

              <StyledTypography gutterBottom>{CATEGORY.value}</StyledTypography>

              <StyledTypographyWhiteSpace fontWeight="700">
                <Trans i18nKey={PRINTER_NAME.translationCode}>{PRINTER_NAME.label}:</Trans>
              </StyledTypographyWhiteSpace>

              <StyledTypography gutterBottom>{PRINTER_NAME.value}</StyledTypography>

              <StyledTypographyWhiteSpace fontWeight="700">
                <Trans i18nKey={PRINT_METHOD.translationCode}>{PRINT_METHOD.label}:</Trans>
              </StyledTypographyWhiteSpace>

              <StyledTypography gutterBottom>{PRINT_METHOD.value}</StyledTypography>
            </Grid>
          </Grid>
        </Box>

        <Box alignItems="flex-end" display="flex" flexDirection="column" width={400}>
          <StyledButton
            onClick={handleOpenJobFilesTab}
            startIcon={<WaveIcon code="info" />}
            variant="contained"
          >
            <Trans i18nKey="file-ser.view_info">View Info</Trans>
          </StyledButton>

          {/* 
          Implement this button later in phase 2 of asset management system
          <StyledButton startIcon={<WaveIcon code="create-new-folder" />}>
            Create/Add to collection
          </StyledButton> */}

          <StyledButton
            onClick={handleDownloadFiles}
            startIcon={<WaveIcon code="cloud-download" />}
          >
            <Trans i18nKey="lib.file.download">Download</Trans>
          </StyledButton>
        </Box>
      </Box>
    </StyledInfoSection>
  );
}

const MemoizedFileSearchInfoSection = memo(FileSearchInfoSection);

export { MemoizedFileSearchInfoSection as FileSearchInfoSection };
