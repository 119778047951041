import { useMemo, useRef, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { StatusIndicator } from 'src/components/StatusIndicator';
import { jobFormApi } from 'src/features/JobForm/JobForm.service';
import { useAppDispatch, useAppSelector, useRouteParams } from 'src/utilities/hooks';
import { showSuccessMessage } from '../../../../utilities/notificationsService';
import {
  SubscriptionsListItem,
  useDeleteSubscriptionMutation,
  useGetSubscriptionsListQuery,
} from '../../JobForm.service';

export function useSubscriptionsList() {
  const { age, jobId } = useRouteParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const rights = useAppSelector((state) => state.user.rights);
  const hasReadPermission = rights.read.includes('job-subs');
  const hasDeletePermission = rights.delete.includes('job-subs');

  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
  const subscriptionsListButton = useRef<HTMLButtonElement | null>(null);

  const { data: subscriptionsList, isFetching } = useGetSubscriptionsListQuery(
    { jobId },
    { skip: jobId === 'new' && age !== 'job' && !hasReadPermission },
  );
  const [deleteSubscription] = useDeleteSubscriptionMutation();

  async function handleRemoveSubscription(subscription: SubscriptionsListItem) {
    if (hasDeletePermission) {
      await deleteSubscription({
        age,
        childJobId: subscription.chd_id,
        childSrc: subscription.chd_src,
        mand: subscription.mand,
        masterJobId: subscription.mas_id,
        masterSrc: subscription.mas_src,
      })
        .unwrap()
        .then((response) => {
          dispatch(jobFormApi.util.invalidateTags(['SubscriptionsList']));
          showSuccessMessage(response.message);
        })
        .catch(({ message }) => console.log(message));
    }
  }

  const subscriptionsListItems = useMemo(
    () =>
      subscriptionsList?.map((subscription) => {
        const { chd_id: childId, chd_src: childSrc } = subscription;

        return {
          code: `${childId}-${childSrc}`,
          delete: hasDeletePermission,
          icon: <StatusIndicator jobType={childSrc} size="extraSmall" />,
          onClick: () => {
            // Temp solution, until we have the following job types implemented in 3.0
            if (
              (childSrc === 'com' || childSrc === 'sec') &&
              import.meta.env.VITE_HUBX_LEGACY_URL
            ) {
              window.open(
                `${
                  import.meta.env.VITE_HUBX_LEGACY_URL
                }index.php?act=job-${childSrc}.edt&jobid=${childId}`,
                '_blank',
              );
            } else {
              navigate(`/jobs-job-${childSrc}/${childId}/job`);
            }

            handleCloseSubMenu();
          },
          onDelete: () => {
            handleCloseSubMenu();
            handleRemoveSubscription(subscription);
          },
          text: String(childId),
        };
      }) ?? [],
    [subscriptionsList],
  );

  function handleCloseSubMenu() {
    setIsSubMenuOpen(false);
  }

  function handleToggleSubMenu() {
    setIsSubMenuOpen((isPreviousSubMenuOpen) => !isPreviousSubMenuOpen);
  }

  return {
    handleCloseSubMenu,
    handleToggleSubMenu,
    isFetching,
    isSubMenuOpen,
    subscriptionsList,
    subscriptionsListButton,
    subscriptionsListItems,
  };
}
