import { MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { LoadingButton } from '@mui/lab';
import { IconButton } from '@mui/material';

import { WaveTooltip } from 'src/components/WaveTooltip';
import { WaveIcon } from 'src/features/WaveIcon';
import { showSuccessMessage } from '../../../../utilities/notificationsService';
import { useLazyGetDownloadUrlQuery } from '../../FileExplorer.service';
import { SelectedFiles } from '../Files/Files';

type BasicProps = {
  code: string;
};
type DownloadButtonProps = BasicProps & (Icon | Named);
type Icon = {
  disabledText?: never;
  fileId: number;
  name?: never;
  onToggleSelectAll?: never;
  onToggleSelectFile: (fileId: number) => void;
  selectedFileIds?: never;
  selectedFiles: SelectedFiles;
  variant: 'icon';
};
type Named = {
  disabledText: string;
  fileId?: never;
  name: string;
  onToggleSelectAll: (arg0: boolean) => void;
  onToggleSelectFile?: never;
  selectedFileIds: number[];
  selectedFiles?: never;
  variant: 'named';
};

export function DownloadButton({
  code,
  disabledText,
  fileId,
  name,
  onToggleSelectAll,
  onToggleSelectFile,
  selectedFileIds,
  selectedFiles,
  variant,
}: DownloadButtonProps) {
  const { t } = useTranslation();
  const [downloadFiles, { isFetching }] = useLazyGetDownloadUrlQuery();

  async function handleDownloadFiles(fileId?: number) {
    downloadFiles({
      file_id: variant === 'named' ? selectedFileIds : ([fileId] as number[]),
    })
      .unwrap()
      .then(({ url }) => {
        if (variant === 'icon' && fileId && selectedFiles[fileId]) {
          onToggleSelectFile(fileId);
        } else if (variant === 'named') onToggleSelectAll(false);

        showSuccessMessage(t('download_successful_content', 'Download was successful'));
        window.open(`${import.meta.env.VITE_API}${url}`);
      });
  }

  return variant === 'named' ? (
    <WaveTooltip
      body={!selectedFileIds.length ? disabledText : ''}
      component={
        <LoadingButton
          color="primary"
          disabled={!selectedFileIds.length}
          loading={isFetching}
          loadingPosition="start"
          onClick={() => handleDownloadFiles()}
          startIcon={<WaveIcon code={`general-files-action-bar-${code}`} />}
          variant="contained"
        >
          {name}
        </LoadingButton>
      }
      key={code}
      placement="top"
      type="simple"
    />
  ) : (
    <IconButton
      onClick={(e: MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        handleDownloadFiles(fileId);
      }}
      size="small"
    >
      <WaveIcon code="general-files-action-bar-files-download" />
    </IconButton>
  );
}
