import { Box } from '@mui/material';

import { WaveIcon } from 'src/features/WaveIcon';

import type { ContentCategoryStatus } from '../../Content.types';

type StatusIconProps = {
  status: ContentCategoryStatus;
  size?: 'small' | 'normal';
};

type IconConfiguration = {
  color: string;
  name: string;
};

const IconMap: { [key in ContentCategoryStatus]: IconConfiguration } = {
  approved: {
    color: 'var(--success)',
    name: 'job-phrase-category-language-approved',
  },
  archived: {
    color: 'primary',
    name: 'job-phrase-category-language-approved',
  },
  draft: {
    color: 'primary',
    name: 'job-phrase-category-language-needed',
  },
  submitted: {
    color: 'var(--success)',
    name: 'job-phrase-category-language-pending',
  },
};

export function StatusIcon({ size = 'normal', status }: StatusIconProps) {
  const state = status ? IconMap[status] : IconMap['draft'];

  return (
    <Box
      code={state.name}
      color={state.color}
      component={WaveIcon}
      height="auto"
      p={size === 'normal' ? 1 : 0}
      sx={{ transform: size === 'small' ? 'scale(0.85)' : 'none' }}
      width="auto"
    />
  );
}
