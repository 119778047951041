import { useEffect, useState } from 'react';
import { UseFieldArrayReturn, useForm, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  Box,
  Button,
  Collapse,
  IconButton,
  Skeleton,
  Stack,
  Unstable_Grid2 as Grid,
} from '@mui/material';
import { styled } from '@mui/material/styles';

import { Dropdown } from 'src/components/RHF/Dropdown/Dropdown';
import { TextField } from 'src/components/RHF/TextField/TextField';
import { WaveTooltip } from 'src/components/WaveTooltip';
import { WaveIcon } from 'src/features/WaveIcon';
import { useAppSelector, useRouteParams } from '../../../../utilities/hooks';
import { showSuccessMessage } from '../../../../utilities/notificationsService';
import {
  useCreateTemplateMutation,
  useGetCategoriesQuery,
  useGetLayoutsQuery,
  useGetTemplateQuery,
  useGetTemplatesQuery,
  useUpdateTemplateMutation,
} from '../../content.service';
import { getCategoriesList } from '../../content.utils';

import type {
  ContentCategoriesList,
  ContentTemplate,
  TemplateSelectionParams,
} from '../../Content.types';

const ActionButton = styled(Button)(() => ({
  minWidth: 0,
  paddingLeft: 8,
  paddingRight: 8,
}));

export function TemplateSelection() {
  const { t } = useTranslation();
  const { age, jobId, jobType } = useRouteParams();
  const userEditRights = useAppSelector((state) => state.user.rights.edit);
  const userCreateRights = useAppSelector((state) => state.user.rights.insert);
  const [isCreationBlockVisible, setIsCreationBlockVisible] = useState(false);
  const [updateTemplate, { isLoading: isUpdateLoading }] = useUpdateTemplateMutation();
  const [createTemplate, { isLoading: isCreateLoading }] = useCreateTemplateMutation();
  // There is no other way to implement this, because of the bug in react-hook-form,
  // which is not fixed yet - https://github.com/react-hook-form/react-hook-form/pull/7544
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { control, fieldArrays, getValues, setValue, trigger } =
    useFormContext<TemplateSelectionParams>();
  const {
    fields: categories,
    move,
    replace,
  } = fieldArrays.categories as UseFieldArrayReturn<TemplateSelectionParams, 'categories'>;

  const {
    control: templateFormControl,
    handleSubmit,
    reset,
    trigger: triggerTemplateNameForm,
  } = useForm<{
    templateName: string;
  }>({
    mode: 'onBlur',
  });

  const {
    data: templates = [],
    isFetching: areTemplatesLoading,
    isSuccess: areTemplatesSuccessfullyLoaded,
  } = useGetTemplatesQuery(null);

  const selectedTemplate = templates.find((template) => template.id === +getValues().template);

  const {
    data: defaultTemplate,
    isFetching: isDefaultTemplateLoading,
    isSuccess: isDefaultTemplateSuccessfullyLoaded,
  } = useGetTemplateQuery({
    age,
    jobId,
    src: jobType,
  });

  const { data: categoriesList = [], isSuccess: areCategoriesLoadedSuccessfully } =
    useGetCategoriesQuery({});

  const { data: layoutsList = {} } = useGetLayoutsQuery({}, { refetchOnMountOrArgChange: true });

  const [categoriesToRender, setCategoriesToRender] = useState<ContentCategoriesList[]>([]);

  function getCurrentlySelectedLayouts(categoryId: string) {
    return getValues().categories.reduce<string[]>((result, category) => {
      if (category.categoryId === categoryId) {
        return [...result, category.layout];
      }

      return result;
    }, []);
  }

  function handleChangeTemplate(value: string) {
    const values: any = [
      ...(templates.find((template) => template.id === +value) || templates[0]).items.map(
        ({ amount, category_layout_id: categoryLayoutId }) => {
          const category: any = categoriesToRender.find((item) => item.id === categoryLayoutId);

          const layouts = category?.layouts;
          const layoutObj: any = layouts
            ? Object.values(layouts).find((x: any) => x.id === categoryLayoutId)
            : {};

          return {
            amount,
            categoryId: category?.code,
            layout: layoutObj?.layout || '',
          };
        },
      ),
      {
        amount: undefined,
        categoryId: '',
        layout: '',
      },
    ];

    setValue('categories', values);
    replace(values);
    setCategoriesToRender(getCategoriesList(categoriesList, getCurrentlySelectedLayouts));
  }

  function handleChangeCategory(index: number) {
    const values = getValues().categories.map(
      ({ amount, categoryId, items, layout }, categoryIndex) => {
        return {
          amount,
          categoryId,
          items: categoryIndex === index ? [] : items,
          layout: categoryIndex === index ? '' : layout,
        };
      },
    );

    if (!getValues().categories.some((category) => !category.categoryId)) {
      values.push({
        amount: undefined,
        categoryId: '',
        items: [],
        layout: '',
      });
    }

    setValue('categories', values);
    replace(values);

    setCategoriesToRender(getCategoriesList(categoriesList, getCurrentlySelectedLayouts));
  }

  async function handleUpdateTemplate() {
    if (!selectedTemplate) {
      return null;
    }

    if (await trigger()) {
      const nonEmptyCategories = getValues()
        .categories.filter(({ categoryId }) => !!categoryId)
        .map(({ amount, categoryId, layout }) => {
          const category: any = categoriesList.find(
            (category: any) => category.code === categoryId,
          );
          const categoryTemplate = category?.layouts.find(
            (categoryLayout: any) => categoryLayout.layout === layout,
          );

          return {
            amount: Number(amount || 0),
            category_layout_id: categoryTemplate?.id,
          };
        });

      updateTemplate({
        description: selectedTemplate.description as string,
        items: nonEmptyCategories,
        name: selectedTemplate.name,
        template_id: selectedTemplate.id as number,
        type: age as string,
      })
        .unwrap()
        .then(() => {
          showSuccessMessage('You successfully updated template');
        });
    }
  }

  async function handleCreateTemplate(data: { templateName: string }) {
    if (await triggerTemplateNameForm()) {
      const nonEmptyCategories = getValues()
        .categories.filter(({ categoryId }) => !!categoryId)
        .map(({ amount, categoryId, layout }) => {
          const category: any = categoriesList.find(
            (category: any) => category.code === categoryId,
          );
          const categoryTemplate = category?.layouts.find(
            (categoryLayout: any) => categoryLayout.layout === layout,
          );

          return {
            amount: Number(amount || 0),
            category_layout_id: categoryTemplate?.id,
          };
        });

      createTemplate({
        items: nonEmptyCategories || [],
        name: data.templateName,
        type: age,
      })
        .unwrap()
        .then((result) => {
          setValue('template', String(result?.id));
          reset();
          setIsCreationBlockVisible(false);
          showSuccessMessage('You have successfully created template');
        });
    }
  }

  async function handleCreateIconClick() {
    setIsCreationBlockVisible((prevState) => !prevState);
  }

  function handleChangeLayout() {
    setCategoriesToRender(getCategoriesList(categoriesList, getCurrentlySelectedLayouts));
  }

  function handleDeleteCategory(index: number) {
    setValue(
      'categories',
      getValues().categories.filter((_, categoryIndex) => categoryIndex !== index),
    );
    setCategoriesToRender(getCategoriesList(categoriesList, getCurrentlySelectedLayouts));
  }

  useEffect(() => {
    const values = getValues();

    if (
      areTemplatesSuccessfullyLoaded &&
      isDefaultTemplateSuccessfullyLoaded &&
      defaultTemplate &&
      !values.initialSetupComplete
    ) {
      const selectedTemplate = templates.find(
        (template) => template.name === defaultTemplate,
      ) as ContentTemplate;
      const template = selectedTemplate?.id?.toString() || '';

      setValue('template', template);
      handleChangeTemplate(template);
    }
  }, [
    isDefaultTemplateSuccessfullyLoaded,
    areTemplatesSuccessfullyLoaded,
    isDefaultTemplateLoading,
  ]);

  useEffect(() => {
    if (areCategoriesLoadedSuccessfully) {
      setCategoriesToRender(getCategoriesList(categoriesList, getCurrentlySelectedLayouts));
    }
  }, [areCategoriesLoadedSuccessfully]);

  if (areTemplatesLoading || isDefaultTemplateLoading) {
    return (
      <Stack gap={1.5} m={0} pt={3} px={3}>
        <Skeleton height={30} variant="rounded" />

        <Skeleton height={30} variant="rounded" />

        <Skeleton height={30} variant="rounded" />

        <Skeleton height={30} variant="rounded" />
      </Stack>
    );
  }

  return (
    <Box component="form" pb={5}>
      <Grid alignItems="flex-start" container m={0} pt={2} px={3} spacing={2}>
        <Grid xs={10}>
          <Dropdown
            control={control}
            label="clip.template"
            name="template"
            onChange={(e) => handleChangeTemplate(e.target.value)}
            options={templates.map((x) => ({ label: x.name, value: x.id ? x.id.toString() : '' }))}
          />

          <Collapse in={isCreationBlockVisible}>
            <Box my={2}>
              <TextField
                control={templateFormControl}
                label="Template name"
                name="templateName"
                rules={{ required: true }}
                shouldShowHelperText={false}
                type="text"
              />

              <Box display="flex" gap={1.5} justifyContent="center" mt={1.5}>
                <Button onClick={handleSubmit(handleCreateTemplate)} type="submit">
                  Create
                </Button>

                <Button color="warning" onClick={() => setIsCreationBlockVisible(false)}>
                  Cancel
                </Button>
              </Box>
            </Box>
          </Collapse>
        </Grid>

        <Grid alignItems="center" display="flex" gap={1} xs={2}>
          <Box width="32px">
            {selectedTemplate && selectedTemplate.description ? (
              <WaveTooltip
                body={selectedTemplate.description}
                component={<WaveIcon code="input-field-information" />}
                placement="top"
                type="simple"
              />
            ) : null}
          </Box>

          {userCreateRights.includes('job-cms.template') ? (
            <ActionButton
              color="secondary"
              disabled={isCreateLoading}
              onClick={handleCreateIconClick}
              variant="contained"
            >
              <WaveIcon code="job-phrase-action-templates-list-add" />
            </ActionButton>
          ) : null}

          {userEditRights.includes('job-cms.template') ? (
            <ActionButton
              color="secondary"
              disabled={!selectedTemplate || isUpdateLoading}
              onClick={handleUpdateTemplate}
              variant="contained"
            >
              <WaveIcon code="job-phrase-action-templates-list-save" />
            </ActionButton>
          ) : null}
        </Grid>
      </Grid>

      {categories.map((category, index) => {
        return (
          <Grid container key={index} m={0} px={3} spacing={2}>
            <Grid xs={6}>
              <Dropdown
                control={control}
                key={category.id}
                label="lib.category"
                name={`categories.${index}.categoryId`}
                onChange={() => handleChangeCategory(index)}
                options={(() => {
                  const items = categoriesToRender
                    .map((x) => ({ label: x.value, value: x.code }))
                    .filter((x) => x.value !== category.categoryId);

                  if (category.categoryId) {
                    items.unshift({
                      label:
                        categoriesList.find((x) => x.code === category.categoryId)?.value || '',
                      value: category.categoryId,
                    });
                  }

                  return items;
                })()}
                rules={categories.length === 1 ? { required: true } : {}}
              />
            </Grid>

            <Grid xs={2}>
              <Dropdown
                control={control}
                disabled={!category.categoryId}
                key={category.id}
                label="lib.layout"
                name={`categories.${index}.layout`}
                onChange={() => handleChangeLayout()}
                options={(() => {
                  const items = Object.values(
                    categoriesToRender.find((x) => x.code === category.categoryId)?.layouts || [],
                  ).map((x: any) => ({
                    label: x.name,
                    value: x.layout,
                  }));

                  const currentLayout = getValues().categories[index]?.layout;

                  if (currentLayout) {
                    items.unshift({
                      label: layoutsList[currentLayout],
                      value: currentLayout,
                    });
                  }

                  return items;
                })()}
                rules={{ required: !!category.categoryId }}
              />
            </Grid>

            <Grid xs={2}>
              <TextField
                control={control}
                key={category.id}
                label={t('lib.amount', 'Amount')}
                name={`categories.${index}.amount`}
                rules={{ required: !!category.categoryId }}
                shouldShowHelperText={false}
                type="number"
              />
            </Grid>

            <Grid alignItems="center" display="flex" gap={1} xs={2}>
              <Box width="40px" />

              {index !== categories.length - 1 ? (
                <>
                  <ActionButton
                    disabled={index === categories.length - 2}
                    onClick={() => move(index, index + 1)}
                    variant="contained"
                  >
                    <WaveIcon code="keyboard-arrow-down" />
                  </ActionButton>

                  <ActionButton
                    disabled={!index}
                    onClick={() => move(index, index - 1)}
                    variant="contained"
                  >
                    <WaveIcon code="keyboard-arrow-up" />
                  </ActionButton>

                  <Box component={IconButton} ml={1} onClick={() => handleDeleteCategory(index)}>
                    <WaveIcon code="delete" />
                  </Box>
                </>
              ) : null}
            </Grid>
          </Grid>
        );
      })}
    </Box>
  );
}
