import { HTMLInputTypeAttribute } from 'react';

import { FieldType } from 'src/pages/Job/Job.service';

export type ExtendedFieldTypes = FieldType | HTMLInputTypeAttribute;

export function isBarcode(type: FieldType) {
  return type === 'ean';
}

export function isCheckbox(type: FieldType | undefined) {
  return type === 'boolean';
}

export function isColor(type: FieldType) {
  return type === 'color';
}

export function isDateField(type: FieldType) {
  return type === 'date';
}

export function isMemo(type: ExtendedFieldTypes) {
  return type === 'memo';
}

export function isNumber(type: FieldType) {
  return (
    type === 'bigint' ||
    type === 'bigintnull' ||
    type === 'int' ||
    type === 'mediumint' ||
    type === 'smallint' ||
    type === 'tinyint'
  );
}

export function isProgressBar(type: FieldType) {
  return type === 'pbar';
}

export function isRichTextField(type: FieldType | string) {
  return type === 'rich';
}

export function isSelectField(type: FieldType | undefined) {
  return (
    type === 'ccomplete' ||
    type === 'cselect' ||
    type === 'gselect' ||
    type === 'pick' ||
    type === 'pickselect' ||
    type === 'resselect' ||
    type === 'select' ||
    type === 'tselect' ||
    type === 'uselect' ||
    type === 'valselect'
  );
}

export function isTextField(type: FieldType) {
  return isBarcode(type) || isMemo(type) || type === 'string' || isColor(type) || isNumber(type);
}

export function isUploadFiles(type: FieldType) {
  return type === 'file';
}
