import { useMemo, useRef, useState } from 'react';
import { useFormState } from 'react-hook-form';

import { useNavigate } from 'react-router-dom';

import {
  useAddBookmarkMutation,
  useDeleteBookmarkMutation,
} from 'src/features/Frame/components/Bookmarks/Bookmarks.service';
import { recentJobsApi } from 'src/features/Frame/components/RecentJobs/RecentJobs.service';
import {
  jobFormApi,
  useGetActionsQuery,
  useReactivateJobMutation,
} from 'src/features/JobForm/JobForm.service';
import { WaveIcon } from 'src/features/WaveIcon';
import { OtherJobValues } from 'src/pages/Job/Job.service';
import { useJobContext } from 'src/pages/Job/JobContext';
import { useAppDispatch, useRouteParams } from 'src/utilities/hooks';
import { showSuccessMessage } from 'src/utilities/notificationsService';

export function useActions() {
  const { age, jobId } = useRouteParams();
  const { isJobFetching, jobType, values } = useJobContext();
  const { isDirty } = useFormState();

  const actionsButton = useRef<HTMLButtonElement | null>(null);
  const [isPerformingAction, setIsPerformingAction] = useState(false);
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
  const [isEmailDialogOpen, setIsEmailDialogOpen] = useState(false);
  const [isIntegrateUpdateDialogOpen, setIsIntegrateUpdateDialogOpen] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [addBookmark] = useAddBookmarkMutation();
  const [deleteBookmark] = useDeleteBookmarkMutation();
  const [reuseJob] = useReactivateJobMutation();

  const { data: actions, isFetching } = useGetActionsQuery(
    { age, jobId, jobType },
    { skip: jobId === 'new' },
  );

  const actionButtons = useMemo(
    () =>
      actions?.map(({ code, text }) => ({
        code,
        icon: (
          <WaveIcon code={`job-form-action-bar-actions-${code}`} sx={{ color: 'primary.main' }} />
        ),
        onClick: () => handleFormActions(code),
        text,
      })) ?? [],
    [actions, values],
  );

  function handleCloseSubMenu() {
    setIsSubMenuOpen(false);
  }

  function handleOpenIntegrateUpdateDialog() {
    setIsIntegrateUpdateDialogOpen(true);
  }

  function handleCloseIntegrateUpdateDialog() {
    setIsIntegrateUpdateDialogOpen(false);
  }

  function handleToggleSubMenu() {
    setIsSubMenuOpen((isPreviousSubMenuOpen) => !isPreviousSubMenuOpen);
  }

  function handlePrint(main: HTMLScriptElement) {
    main.style.setProperty('overflow', 'visible');
    window.print();
  }

  async function handleAddBookmark() {
    setIsPerformingAction(true);
    await addBookmark({
      age,
      jobid: jobId,
      keyword: (values as OtherJobValues).stichw,
      src: jobType,
    })
      .unwrap()
      .then((message) => {
        dispatch(jobFormApi.util.invalidateTags(['Actions']));
        showSuccessMessage(message);
      })
      .catch((error) => console.log(error));
    setIsPerformingAction(false);
  }

  async function handleRemoveBookmark() {
    setIsPerformingAction(true);
    await deleteBookmark({
      jobid: jobId,
    })
      .unwrap()
      .then((message) => {
        dispatch(jobFormApi.util.invalidateTags(['Actions']));
        showSuccessMessage(message);
      })
      .catch((error) => console.log(error));
    setIsPerformingAction(false);
  }

  function handleAssignProject() {
    navigate(`/jobs-job-${jobType}/${jobId}/assign`);
  }

  async function handleReuseJob() {
    setIsPerformingAction(true);
    await reuseJob({
      jobId,
      jobType,
    })
      .unwrap()
      .then((message) => {
        navigate(`/jobs-job-${jobType}/${jobId}/job`);
        dispatch(recentJobsApi.util.invalidateTags(['RecentJobs']));
        showSuccessMessage(message);
      })
      .catch((error) => console.log(error));
    setIsPerformingAction(false);
  }

  function handleCloseDialog() {
    setIsEmailDialogOpen(false);
  }

  function handleFormActions(code: string) {
    handleCloseSubMenu();
    const main = document.getElementsByTagName('main')[0] as HTMLScriptElement;

    switch (code) {
      case 'print-job':
        return handlePrint(main);
      case 'add-bookmark':
        return handleAddBookmark();
      case 'remove-bookmark':
        return handleRemoveBookmark();
      case 'reassign-project':
      case 'assign-to-project':
        return handleAssignProject();
      case 'email':
        return setIsEmailDialogOpen(true);
      case 'reuse':
        return handleReuseJob();
      case 'integrate-update':
        return handleOpenIntegrateUpdateDialog();
      default:
        console.warn(`${code} action not matched`);
    }

    main.style.setProperty('overflow', 'auto');
  }

  return {
    actionButtons,
    actionsButton,
    handleCloseDialog,
    handleCloseIntegrateUpdateDialog,
    handleCloseSubMenu,
    handleToggleSubMenu,
    isDirty,
    isEmailDialogOpen,
    isFetching: isFetching || isJobFetching,
    isIntegrateUpdateDialogOpen,
    isPerformingAction,
    isSubMenuOpen,
  };
}
